$enable-validation-icons: false;

$gray-100: #f5f5f4;
$gray-200: #e7e5e4;
$gray-300: #d6d3d1;
$gray-400: #a8a29e;
$gray-500: #78716c;
$gray-600: #57534e;
$gray-700: #44403c;
$gray-800: #292524;
$gray-900: #1c1917;

$slate-100: #f1f5f9;
$slate-200: #e2e8f0;
$slate-300: #cbd5e1;
$slate-400: #94a3b8;
$slate-500: #64748b;
$slate-600: #475569;
$slate-700: #334155;
$slate-800: #1e293b;
$slate-900: #0f172a;

$blue-50: #b6d6ea;
$blue-100: #85bbdc;
$blue-200: #6dadd5;
$blue-300: #55a0ce;
$blue-400: #3c92c7;
$blue-500: #3381b0;
$blue-600: #2c6f98;
$blue-700: #286489;
$blue-800: #23597a;
$blue-900: #1f4e6a;

$green-100: #b0d385;
$green-200: #a7ce78;
$green-300: #97c560;
$green-400: #88bd48;
$green-500: #77a73d;
$green-600: #669034;
$green-700: #55782b;
$green-800: #446023;
$green-900: #33481a;

$red-100: #ff7777;
$red-200: #ff6666;
$red-300: #ff5555;
$red-400: #ff3333;
$red-500: #ff1111;
$red-600: #ee0000;
$red-700: #cc0000;
$red-800: #aa0000;
$red-900: #880000;

$orange: #f07c00;
$blue-dark: #597eaa;
$green: #3A833C;
$indigo: #818cf8;
$pink: #e41b68;

$primary: $pink;
$primary-darkened: darken($primary, 10%);
$secondary: $blue-600;
$danger: $red-600;
$success: $green;
$info: #128091;

$font-family-sans-serif: "Source Sans Pro", sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

$h1-font-size: $font-size-base * 1.75;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1.25;
$h4-font-size: $font-size-base * 1.2;
$h5-font-size: $font-size-base * 1.1;
$h6-font-size: $font-size-base;

$text-light: $gray-400;
$text-regular: $gray-600;
$text-dark: $gray-800;
$text-white: #ffffff;

$highlight: #ffff65;
$econlowdown-color: $green-200;
$link-color: $blue-600;
$link-green: $green-700;
$form-feedback-invalid-color: $red-300;
$form-feedback-invalid-text-color: $red-600;

$notice-font-weight: 600;

$background-white: #ffffff;
$background-gray-light: $gray-100;
$border-gray-light: $gray-200;
$background-gray-medium: $gray-300;
$border-gray-medium: $gray-400;
$background-gray-dark: $gray-500;
$border-gray-dark: $gray-600;
$background-black: $gray-800;
$background-blue: $blue-dark;
$background-blue-bright: #00b0ff;
$background-orange: $orange;

$modal-close-color: $gray-500;
$modal-backdrop-background: rgba(0, 0, 0, 0.2);

$btn-close-opacity: 0.75;
$btn-close-hover-opacity: 1;

$component-active-bg: $secondary;

$accordion-button-bg: $background-gray-light;
$accordion-button-active-bg: $background-gray-light;
$accordion-button-color: $text-dark;
$accordion-button-active-color: $text-dark;

$focus-ring-color: rgba($component-active-bg, .45);
$input-btn-focus-color: rgba($component-active-bg, .45);
$input-focus-border-color: lighten($component-active-bg, 35%);

$small-navbar-breakpoint: 535px;

@function hexToRGB($hex) {
    @return red($hex), green($hex), blue($hex);
}
